import { ProductSetProps } from '../types/productProps';

function isEditModeDisabled(props: ProductSetProps): boolean {
    const { _ } = props;
    return (
        _ === undefined ||
        _.pageBuilderData === undefined ||
        _.pageBuilderData.previewState === undefined ||
        _.pageBuilderData.previewState.editMode === undefined ||
        _.pageBuilderData.previewState.editMode === false
    );
}

export default isEditModeDisabled;
