import { svg } from 'lit-html';

import { PRODUCT_SET } from '../../constants';

export const enum ArrowSide {
    LEFT = 'left',
    RIGHT = 'right',
}

export const LEFT_ARROW = `${PRODUCT_SET}__arrow--left`;
export const RIGHT_ARROW = `${PRODUCT_SET}__arrow--right`;

export const leftArrowIcon = svg`
    <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4507 0.548363C14.7208 -0.182786 13.544 -0.182786 12.8141 0.548362L0.435702 12.948C-0.145236 13.53 -0.145236 14.47 0.435702 15.052L12.8141 27.4516C13.544 28.1828 14.7208 28.1828 15.4507 27.4516C16.1806 26.7205 16.1806 25.5417 15.4507 24.8106L4.66612 13.9925L15.4656 3.17453C16.1806 2.4583 16.1806 1.26459 15.4507 0.548363Z" fill="white"/>
    </svg>
`;

export const rightArrowIcon = svg`
    <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.549296 27.4516C1.27919 28.1828 2.45596 28.1828 3.18586 27.4516L15.5643 15.052C16.1452 14.47 16.1452 13.53 15.5643 12.948L3.18586 0.548364C2.45597 -0.182786 1.2792 -0.182786 0.549299 0.548363C-0.180597 1.27951 -0.180597 2.4583 0.549299 3.18945L11.3339 14.0075L0.534404 24.8255C-0.180597 25.5417 -0.180599 26.7354 0.549296 27.4516Z" fill="white"/>
    </svg>
`;
