import { css } from 'emotion';

import { BULLET_ACTIVE_CLASS_NAME } from './constants';

export const productSetBullets = (arrowsEnabled: boolean): string => css`
    display: none;

    @media screen and (min-width: 801px) {
        -webkit-touch-callout: none;
        user-select: none;
        position: absolute;
        padding: 8px;
        z-index: 100;
        left: 50%;
        display: ${arrowsEnabled && 'inline-flex'};
        list-style: none;
        transform: translateX(-50%);

        .${BULLET_ACTIVE_CLASS_NAME} {
            background-color: #8c93ad;
        }
    }
`;

export const productSetBullet = css`
    width: 12px;
    height: 12px;
    border: 1px solid #8c93ad;
    background: #ffffff;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
    line-height: 0;
    margin: 4px;

    :focus {
        outline: none;
    }
`;
