import { svg, TemplateResult } from 'lit-html';

export const defaultImageIcon = svg`
<svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="256" height="256" fill="#D9DCE9"/>
    <path
        d="M127.73 96.0125C127.479 96.0377 127.233 96.0967 127 96.1875L97.5947 107.388C97.1206 107.571 96.716
        107.88 96.4321 108.276C96.1482 108.673 95.9978 109.138 96 109.613V146.414C96.0032 146.885 96.156 147.345
        96.4396 147.736C96.7231 148.127 97.1248 148.433 97.5947 148.614L127 159.815C127.317 159.937 127.657 160 128
        160C128.343 160 128.683 159.937 129 159.815L158.405 148.614C158.875 148.433 159.277 148.127 159.56
        147.736C159.844 147.345 159.997 146.885 160 146.414V109.613C160.002 109.138 159.852 108.673 159.568
        108.276C159.284 107.88 158.879 107.571 158.405 107.388L129 96.1875C128.599 96.0317 128.162 95.9715 127.73
        96.0125ZM128 100.988L150.595 109.613L128 118.213L105.405 109.613L128 100.988ZM101.189 113.188L125.405
        122.413V154.014L101.189 144.789V113.188ZM154.811 113.188V144.789L130.595 154.014V122.413L154.811 113.188Z"
        fill="white"
    />
</svg>
`;

export const star = (color: string): TemplateResult => {
    return svg`
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.52447 1.46353C9.67415 1.00287 10.3259 1.00287 10.4755 1.46353L12.1329 6.56434C12.1998 6.77035
                12.3918 6.90983 12.6084 6.90983H17.9717C18.4561 6.90983 18.6575 7.52964 18.2656 7.81434L13.9266
                10.9668C13.7514 11.0941 13.678 11.3198 13.745 11.5258L15.4023 16.6266C15.552 17.0873 15.0248 17.4704
                14.6329 17.1857L10.2939 14.0332C10.1186 13.9059 9.88135 13.9059 9.70611 14.0332L5.3671 17.1857C4.97524
                17.4704 4.448 17.0873 4.59768 16.6266L6.25503 11.5258C6.32197 11.3198 6.24864 11.0941 6.07339 10.9668L1.73438
                7.81434C1.34253 7.52964 1.54392 6.90983 2.02828 6.90983H7.39159C7.6082 6.90983 7.80018 6.77035 7.86712
                6.56434L9.52447 1.46353Z" fill="${color}"
            />
        </svg>
    `;
};

export const halfStar = (fullColor: string, emptyColor: string): TemplateResult => {
    return svg`
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.52447 1.46353C9.67415 1.00287 10.3259 1.00287 10.4755 1.46353L12.1329 6.56434C12.1998 6.77035
                12.3918 6.90983 12.6084 6.90983H17.9717C18.4561 6.90983 18.6575 7.52964 18.2656 7.81434L13.9266
                10.9668C13.7514 11.0941 13.678 11.3198 13.745 11.5258L15.4023 16.6266C15.552 17.0873 15.0248 17.4704
                14.6329 17.1857L10.2939 14.0332C10.1186 13.9059 9.88135 13.9059 9.70611 14.0332L5.3671 17.1857C4.97524
                17.4704 4.448 17.0873 4.59768 16.6266L6.25503 11.5258C6.32197 11.3198 6.24864 11.0941 6.07339 10.9668L1.73438
                7.81434C1.34253 7.52964 1.54392 6.90983 2.02828 6.90983H7.39159C7.6082 6.90983 7.80018 6.77035 7.86712
                6.56434L9.52447 1.46353Z" fill="${emptyColor}"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 1.125C9.79968 1.125 9.59934 1.24016 9.5245 1.47049L7.86714 6.5713C7.80021 6.77732 7.60823 6.9168
                7.39161 6.9168H2.0283C1.54394 6.9168 1.34255 7.5366 1.73441 7.8213L6.07342 10.9738C6.24866 11.1011
                6.32199 11.3268 6.25506 11.5328L4.5977 16.6336C4.44803 17.0943 4.97527 17.4773 5.36712 17.1926L9.70613
                14.0402C9.79375 13.9765 9.89689 13.9447 10 13.9447V1.125Z" fill="${fullColor}"
            />
        </svg>
    `;
};
