import { ImageFit } from '@bigcommerce/page-builder-sdk';

import { ProductCardProps, ProductSetProps, ProductValue } from '../types/productProps';
import { ProductElement } from '../types/productDynamicData';
import { ActionTypes, ActionLabels } from '../types/actions';

import { DEFAULT_CURRENCY, DEFAULT_LOCALE, DEFAULT_PRODUCT_TITLE } from './constants';

const buildProductCard = (props: ProductSetProps): ProductCardProps[] => {
    const {
        button: { buttonActionType },
        displayLocale,
        productImage,
        productRating: { starColorEmpty, starColorFilled },
    } = props;
    const activeCurrency = props._.context && props._.context.activeCurrencyCode;

    return props.product.value.map(
        (product: ProductValue): ProductCardProps => {
            const productProps = {
                actionLabel: ActionLabels[buttonActionType],
                actionLink: '#',
                brand: 'Brand',
                currencyCode: activeCurrency || DEFAULT_CURRENCY,
                displayLocale: displayLocale || DEFAULT_LOCALE,
                path: '#',
                name: DEFAULT_PRODUCT_TITLE,
                priceWithTax: Math.floor(Math.random() * 4000) / 10.0,
                priceWithoutTax: Math.floor(Math.random() * 4000) / 10.0,
                productImageFit: productImage.imageFit || ImageFit.Fit,
                rating: 2.5 + Math.floor(Math.random() * 5) / 2,
                starColorEmpty,
                starColorFilled,
                totalReviews: Math.floor(Math.random() * 50),
            };

            const currencyNode = props._.data && props._.data.site && props._.data.site.currency;
            const productNode =
                props._.data &&
                props._.data.site &&
                props._.data.site.products &&
                props._.data.site.products.edges &&
                props._.data.site.products.edges.find(
                    (productEdge: ProductElement): boolean => {
                        return productEdge.node.entityId === parseInt(product.productId, 10);
                    }
                );

            if (productNode && productNode.node) {
                const {
                    defaultImage,
                    name,
                    path,
                    priceWithTax: priceWithTaxNode,
                    priceWithoutTax: priceWithoutTaxNode,
                } = productNode.node;

                const brandName = productNode.node.brand ? productNode.node.brand.name : '';
                const { summationOfRatings, numberOfReviews } = productNode.node.reviewSummary;
                const priceWithTaxObject = priceWithTaxNode && priceWithTaxNode.price;
                const priceWithoutTaxObject = priceWithoutTaxNode && priceWithoutTaxNode.price;
                const { currencyCode, value: priceWithoutTax } = priceWithoutTaxObject || {};
                const { value: priceWithTax } = priceWithTaxObject || {};
                const rating = summationOfRatings / numberOfReviews || 0;
                const productNodeImages = defaultImage || undefined;

                let { actionLink } = productProps;
                if (buttonActionType === ActionTypes.AddToCard) {
                    actionLink = productNode.node.addToCartUrl;
                } else if (buttonActionType === ActionTypes.AddToWishlist) {
                    actionLink = productNode.node.addToWishlistUrl;
                } else if (buttonActionType === ActionTypes.GoToProduct) {
                    actionLink = path;
                }

                return {
                    ...productProps,
                    actionLink,
                    brand: brandName,
                    currencyCode,
                    currencyDisplay: currencyNode,
                    name,
                    path,
                    priceWithTax,
                    priceWithoutTax,
                    productImages: productNodeImages,
                    rating,
                    totalReviews: numberOfReviews,
                };
            }

            return productProps;
        }
    );
};

export default buildProductCard;
