import { html, TemplateResult } from 'lit-html';

import { ProductCardProps, ProductSetProps } from '../../types/productProps';
import buildProductCard from '../../services/productFactory';
import productCard from '../productCards/productCards';
import arrows from '../arrows/arrows';
import { productSetCarouselSelector, productSetContainer } from '../../constants';
import { defaultProductCardProps, DEFAULT_CURRENCY } from '../../services/constants';
import { productSetBulletBlock } from '../bullets/bullets';

import { contentStyle, productContainerStyles } from './styles';

function renderProductCards(props: ProductSetProps) {
    const {
        productsPerSlide,
        product: { value },
    } = props;
    const productCards = buildProductCard(props);
    const contextCurrency = props._.context && props._.context.activeCurrencyCode;
    const activeCurrency = contextCurrency || DEFAULT_CURRENCY;
    const currencyCode = productCards.length ? productCards[0].currencyCode : activeCurrency;
    // fill empty space with default products;
    if (value.length < productsPerSlide) {
        for (let i = productCards.length; i < productsPerSlide; i += 1) {
            productCards.push(defaultProductCardProps(currencyCode));
        }
    }

    return productCards.map((card: ProductCardProps): TemplateResult => productCard(props, card));
}

const productSet = (props: ProductSetProps): TemplateResult => {
    const {
        _: { data, id },
    } = props;

    if (data && data.error) {
        return html`
            <!-- Unable to render widget - error fetching storefront api data -->
        `;
    }
    const productCards = renderProductCards(props);
    const isArrowsVisibleMobileView = productCards.length > 2;
    const isArrowsVisibleDesktopView = productCards.length > props.productsPerSlide;

    return html`
        <div id="${productSetContainer(id)}" class="${productContainerStyles(isArrowsVisibleDesktopView)}">
            <div id="${productSetCarouselSelector(id)}" class="${contentStyle}">
                ${productCards}
            </div>

            ${arrows(isArrowsVisibleMobileView, isArrowsVisibleDesktopView)}
            ${productSetBulletBlock(props, isArrowsVisibleDesktopView)}
        </div>
    `;
};

export default productSet;
