import Siema from 'siema';
import { html, TemplateResult } from 'lit-html';

import { markActiveBullet } from '../bullets/bullets';

import { arrowStyles, productSetArrowsStyles } from './styles';
import { ArrowSide, LEFT_ARROW, leftArrowIcon, RIGHT_ARROW, rightArrowIcon } from './constants';

export const setupCarouselArrows = (
    container: HTMLElement,
    carousel: Siema,
    productsPerSlide: number,
    getEditMode: () => boolean
): void => {
    const leftArrow = container.querySelector(`.${LEFT_ARROW}`);
    const rightArrow = container.querySelector(`.${RIGHT_ARROW}`);

    if (leftArrow && rightArrow) {
        leftArrow.addEventListener(
            'click',
            (): void => {
                const editMode = getEditMode();
                if (editMode) {
                    return;
                }
                carousel.prev(carousel.perPage);
                markActiveBullet(container, carousel, productsPerSlide);
            }
        );
        rightArrow.addEventListener(
            'click',
            (): void => {
                const editMode = getEditMode();
                if (editMode) {
                    return;
                }
                carousel.next(carousel.perPage);
                markActiveBullet(container, carousel, productsPerSlide);
            }
        );
    }
};

const arrows = (isArrowsVisibleMobileView: boolean, isArrowsVisibleDesktopView: boolean): TemplateResult => {
    return html`
        <div class="${productSetArrowsStyles(isArrowsVisibleMobileView, isArrowsVisibleDesktopView)}">
            <button class="${arrowStyles(ArrowSide.LEFT)} ${LEFT_ARROW}" data-test-id="product-set-left-arrow">
                ${leftArrowIcon}
            </button>
            <button class="${arrowStyles(ArrowSide.RIGHT)} ${RIGHT_ARROW}" data-test-id="product-set-right-arrow">
                ${rightArrowIcon}
            </button>
        </div>
    `;
};

export default arrows;
