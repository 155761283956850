import { css } from 'emotion';

import { ArrowSide } from './constants';

export const productSetArrowsStyles = (
    isArrowsVisibleMobileView: boolean,
    isArrowsVisibleDesktopView: boolean
): string => {
    return css`
        -webkit-touch-callout: none;
        user-select: none;
        visibility: ${isArrowsVisibleMobileView ? 'visible' : 'hidden'};

        @media screen and (min-width: 801px) {
            visibility: ${isArrowsVisibleDesktopView ? 'visible' : 'hidden'};
        }
    `;
};

export const arrowStyles = (arrowSide: ArrowSide): string => css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 40%;
    z-index: 100;
    border: none;
    color: #5e637a;
    background: #313440;
    opacity: 0.8;
    height: 50px;
    width: 40px;
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
    transform: translateY(-50%);
    ${arrowSide === ArrowSide.LEFT ? 'left: 0em;' : 'right: 0em;'}

    :focus {
        outline: 0;
    }
`;
