export const enum ActionTypes {
    AddToCard = 'addToCart',
    AddToWishlist = 'addToWishlist',
    GoToProduct = 'goToProduct',
}

export const ActionLabels = {
    [ActionTypes.AddToCard]: 'Add to Cart',
    [ActionTypes.AddToWishlist]: 'Add to Wishlist',
    [ActionTypes.GoToProduct]: 'Details',
};
