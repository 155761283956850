import { ButtonStyle } from '@bigcommerce/page-builder-sdk';

const renderBorderRadius = (buttonStyle: ButtonStyle): string => {
    switch (buttonStyle) {
        case ButtonStyle.Pill:
            return 'border-radius: 25px';
        case ButtonStyle.Round:
            return 'border-radius: 4px';
        case ButtonStyle.Square:
            return 'border-radius: 0px';
        default:
            return '';
    }
};

export default renderBorderRadius;
