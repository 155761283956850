import { ImageFit } from '@bigcommerce/page-builder-sdk';

import { ActionLabels, ActionTypes } from '../types/actions';
import { ProductCardProps } from '../types/productProps';

export const DEFAULT_CURRENCY = 'USD';
export const DEFAULT_PRODUCT_TITLE = 'Product name';
export const DEFAULT_LOCALE = 'en-us';

export const widgetSelector = (uuid: string): string => `#sd-product-set-${uuid}`;

export const defaultProductCardProps = (defaultCurrencyCode: string): ProductCardProps => ({
    actionLabel: ActionLabels[ActionTypes.AddToCard],
    actionLink: '#',
    brand: 'Brand',
    currencyCode: defaultCurrencyCode,
    displayLocale: DEFAULT_LOCALE,
    path: '#',
    name: DEFAULT_PRODUCT_TITLE,
    priceWithoutTax: Math.floor(Math.random() * 4000) / 10.0,
    priceWithTax: Math.floor(Math.random() * 4000) / 10.0,
    productImageFit: ImageFit.Fit,
    rating: 2.5 + Math.floor(Math.random() * 5) / 2,
    starColorEmpty: '#3C64F4',
    starColorFilled: '#ECEEF5',
    totalReviews: Math.floor(Math.random() * 50),
});
