import { css } from 'emotion';

export const productContainerStyles = (bulletsEnabled: boolean): string => css`
    width: 100%;
    box-sizing: border-box;
    height: auto;
    position: relative;
    background: transparent;

    @media screen and (min-width: 801px) {
        ${bulletsEnabled ? 'margin-bottom: 30px;' : ''}
    }
`;

export const contentStyle = css`
    height: 100%;
    width: 100%;
`;
