import { html, TemplateResult } from 'lit-html';

import { halfStar, star } from '../constants';

const TOTAL_STARS = 5;

const ratingStars = (rating: number, fullColor: string, emptyColor: string): TemplateResult => {
    const starInterval = 100 / TOTAL_STARS;
    const ratingPercentage = rating / TOTAL_STARS;
    const hundredRating = Math.round(ratingPercentage * 100);

    const stars = [];
    for (let i = 0; i < TOTAL_STARS; i += 1) {
        const currentValMin = i * starInterval;
        const currentValMax = currentValMin + starInterval;

        if (hundredRating >= currentValMax) {
            stars.push(star(fullColor));
        } else if (hundredRating > currentValMin) {
            stars.push(halfStar(fullColor, emptyColor));
        } else {
            stars.push(star(emptyColor));
        }
    }

    return html`
        ${stars}
    `;
};

export default ratingStars;
