import { handleVisibility, ImageFit, renderFlexBoxAlignment } from '@bigcommerce/page-builder-sdk';
import { css } from 'emotion';

import {
    BrandElement,
    PriceElement,
    ProductCardProps,
    ProductNameElement,
    ProductSetProps,
    TextStyle,
} from '../../types/productProps';
import renderBorderRadius from '../../lib/borderRadius';

export const productCardContainer = ({ productCardContentAlignment }: ProductSetProps): string => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    align-items: ${renderFlexBoxAlignment(productCardContentAlignment.horizontal)};
`;

export const productDefaultImageStyle = ({ productImage }: ProductSetProps): string => css`
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    object-fit: cover;
    object-position: center;
    background: #d9dce9;
    display: flex;
    justify-content: center;
    align-items: center;
    ${handleVisibility(productImage.visibility)};
`;

export const productLinkStyle = ({ productImage }: ProductSetProps): string => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    ${handleVisibility(productImage.visibility)};
`;

export const productImageStyle = ({ productImageFit }: ProductCardProps): string => css`
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: ${productImageFit === ImageFit.Fill ? 'cover' : 'fit'};
    object-position: center;
    position: absolute;
`;

export const productImageSrcSet = ({ productImages }: ProductCardProps): string => {
    if (productImages) {
        const { url80wide, url160wide, url320wide, url640wide, url960wide, url1280wide, url1920wide } = productImages;

        return `${url80wide} 80w, ${url160wide} 160w, ${url320wide} 320w, ${url640wide} 640w, ${url960wide} 960w, ${url1280wide} 1280w, ${url1920wide} 1920w`;
    }

    return '';
};

const productBrandDefaults = (textElement: BrandElement): string => {
    const { textStyle } = textElement;
    const customFontSize = `${textElement.fontSize.value}${textElement.fontSize.type}`;
    switch (textStyle) {
        case TextStyle.DEFAULT:
            return css`
                font-size: 13px;
                font-weight: 500;
                line-height: 21px;
                text-transform: uppercase;

                @media screen and (min-width: 801px) {
                    font-size: 15px;
                    line-height: 23px;
                }
            `;
        case TextStyle.CUSTOM:
            return css`
                font-size: ${customFontSize};
                font-weight: ${textElement.fontWeight};
                font-family: ${textElement.fontFamily};
            `;
        default:
            return '';
    }
};

export const productBrandStyle = ({ brand }: ProductSetProps): string => css`
    ${handleVisibility(brand.visibility)};
    ${productBrandDefaults(brand)};
    color: ${brand.color};
    margin-top: 16px;
`;

const getTextDefaults = (textElement: ProductNameElement | PriceElement): string => {
    const { textStyle } = textElement;
    const customFontSize = `${textElement.fontSize.value}${textElement.fontSize.type}`;
    switch (textStyle) {
        case TextStyle.DEFAULT:
            return css`
                font-size: 13px;
                font-weight: 400;
                line-height: 21px;

                @media screen and (min-width: 801px) {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 23px;
                }
            `;
        case TextStyle.CUSTOM:
            return css`
                font-size: ${customFontSize};
                font-weight: ${textElement.fontWeight};
                font-family: ${textElement.fontFamily};
            `;
        default:
            return '';
    }
};

export const productNameStyle = ({ productName }: ProductSetProps): string => css`
    ${handleVisibility(productName.visibility)};
    ${getTextDefaults(productName)};
    text-align: center;

    a {
        color: ${productName.color};
        text-decoration: none;
    }
`;

export const productRatingStyle = ({ productCardContentAlignment, productRating }: ProductSetProps): string => {
    return css`
        display: flex;
        align-items: center;
        justify-content: ${renderFlexBoxAlignment(productCardContentAlignment.horizontal)};
        padding: 0 12px;
        margin-top: 8px;
        ${handleVisibility(productRating.visibility)};

        span {
            margin-left: 6px;
            color: ${productRating.reviewsColor};
        }
    `;
};

export const productPriceStyle = ({ price }: ProductSetProps): string => css`
    color: ${price.color};
    ${handleVisibility(price.visibility)};
    ${getTextDefaults(price)};
`;

export const actionStyle = ({ button }: ProductSetProps): string => css`
    padding: 0 12px;
    margin-top: 16px;
    ${handleVisibility(button.visibility)};
`;

export const buttonStyle = ({ button }: ProductSetProps): string => css`
    color: ${button.buttonTextColor};
    background: ${button.buttonColor};
    text-decoration: none;
    padding: 10px 20px;
    display: block;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    ${renderBorderRadius(button.shape)};
`;
