import Siema from 'siema';
import { html, TemplateResult } from 'lit-html';

import { ProductSetProps } from '../../types/productProps';

import { productSetBullet, productSetBullets } from './styles';
import { BULLET_ACTIVE_CLASS_NAME, BULLET_CLASS_NAME } from './constants';

const setActive = (bullet: HTMLElement): void => {
    bullet.classList.add(BULLET_ACTIVE_CLASS_NAME);
};

const getBullets = (container: HTMLElement): Element[] => {
    return Array.from(container.querySelectorAll(`.${BULLET_CLASS_NAME}`));
};

const clearAll = (bullets: Element[]): void => {
    // TODO: Find out a better approach to unhighlight a bullet
    bullets.forEach((bullet: Element): void => bullet.classList.remove(BULLET_ACTIVE_CLASS_NAME));
};

const clickListener = (
    productSetCarousel: Siema,
    container: HTMLElement,
    event: Event,
    productsPerSlide: number
): void => {
    const bulletElement = event.target as HTMLElement;
    const bullets = getBullets(container);

    clearAll(bullets);

    const selected = parseInt(bulletElement.dataset.bulletIndex, 10);
    productSetCarousel.goTo(productsPerSlide * selected);
    setActive(bullets[selected] as HTMLElement);
};

export const markActiveBullet = (container: HTMLElement, productSetCarousel: Siema, productsPerSlide: number): void => {
    const bulletIndex = Math.ceil(productSetCarousel.currentSlide / productsPerSlide);
    const bullets = getBullets(container);
    if (bullets.length) {
        clearAll(bullets);

        if (bullets[bulletIndex]) {
            setActive(bullets[bulletIndex] as HTMLElement);
        }
    }
};

export const setupCarouselBullets = (
    container: HTMLElement,
    productSetCarousel: Siema,
    productsPerSlide: number,
    getEditMode: () => boolean
): void => {
    markActiveBullet(container, productSetCarousel, productsPerSlide);
    getBullets(container).forEach(
        (bullet: Element): void => {
            bullet.addEventListener(
                'click',
                (event: Event): void => {
                    const editMode = getEditMode();
                    if (editMode) {
                        return;
                    }
                    clickListener(productSetCarousel, container, event, productsPerSlide);
                }
            );
        }
    );
};

export const productSetBulletElement = (numberOfBullets: number): TemplateResult[] => {
    const bullets = [];

    for (let i = 0; i < numberOfBullets; i += 1) {
        const productHtml = html`
            <button
                class="${productSetBullet} ${BULLET_CLASS_NAME}"
                data-bullet-index="${i}"
                data-test-id="product-set-bullet-${i}"
            ></button>
        `;

        bullets.push(productHtml);
    }

    return bullets;
};

export const productSetBulletBlock = (props: ProductSetProps, bulletsEnabled: boolean): TemplateResult => {
    const numberOfBullets = Math.ceil(props.product.value.length / props.productsPerSlide);
    const productBullets = productSetBulletElement(numberOfBullets);

    return html`
        <div class="${productSetBullets(bulletsEnabled)}">${productBullets}</div>
    `;
};
