import { render } from 'lit-html';

import productSet from '../components/productSet/productSet';
import { ProductSetProps } from '../types/productProps';

import { widgetSelector } from './constants';

const renderProduct = (props: ProductSetProps): void => {
    const widget = document.querySelector(widgetSelector(props._.id)) as HTMLElement;

    if (widget) {
        render(productSet(props), widget);
    }
};

export default renderProduct;
