export const PRODUCT_SET = 'sd-product-set';
export const productSetSelector = (uuid: string): string => `${PRODUCT_SET}-${uuid}`;
export const productSetId = (uuid: string): string => `#${productSetSelector(uuid)}`;

export const PRODUCT_SET_CONTAINER = 'sd-product-set-container';
export const productSetContainer = (uuid: string): string => `${PRODUCT_SET_CONTAINER}-${uuid}`;
export const productSetContainerId = (uuid: string): string => `#${productSetContainer(uuid)}`;

export const PRODUCT_SET_CAROUSEL = 'sd-product-set-carousel';
export const productSetCarouselSelector = (uuid: string): string => `${PRODUCT_SET_CAROUSEL}-${uuid}`;
export const productSetCarouselId = (uuid: string): string => `#${productSetCarouselSelector(uuid)}`;

export const TAX_OPTIONS = {
    INCLUDED: 'INC',
    EXCLUDED: 'EX',
    BOTH: 'BOTH',
};
